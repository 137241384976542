<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nom"
            label-for="account-username"
          >
            <b-form-input
              v-model="generalData.C_nom"
              placeholder="Nom"
              name="C_nom"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Prénom"
            label-for="account-name"
          >
            <b-form-input
              v-model="generalData.C_prenom"
              name="C_prenom"
              placeholder="Prénom"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="generalData.C_email"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Société"
            label-for="account-company"
          >
            <b-form-input
              v-model="generalData.company"
              name="company"
              placeholder="Raison sociale"
            />
          </b-form-group>
        </b-col>
<!-- 
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            {{ $t('Save changes') }} 
          </b-button>
        </b-col>
 -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
   BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    // optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
	//	optionsLocal: this.generalData,
      profileFile: null,
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
